import React, { useEffect, useState } from 'react';
import { buildLogger, LoggableError } from 'logger';
import loadable from '@loadable/component';
import StackTrace from 'stacktrace-js';

import useCanAddPass from 'hooks/use-can-add-pass';
import { hasPasses } from 'state/selectors/has-passes';

import { SkeletonHomeView, SkeletonHomeAdvertisingView } from 'views/home/skeleton';
import { useNavigationContext } from 'contexts/navigation-context';

const LazyHomeLoggedIn = loadable(() => import('suspended-page/home'));

const HomePage = () => {
  const logger = buildLogger();
  const [loading, setLoading] = useState(true);
  const [hasPass, setHasPass] = useState(true);
  const {
    canAdd,
    hasSub,
    authenticated,
    canAddLoading,
  } = useCanAddPass();

  const { landingPageOnly = () => {} } = useNavigationContext();

  useEffect(() => {
    const fetchSub = async () => {
      try {
        const has = await hasPasses();
        setHasPass(has);
      } catch (err) {
        const trace = await StackTrace.get();
        const { stack = '', message = '' } = err;
        logger.error(new LoggableError({
          data: { stack, caller: 'fetchSub:onError' },
          message,
          trace,
        }));
      } finally {
        setLoading(false);
      }
    };

    if (authenticated && !canAddLoading) {
      fetchSub();
    }

    // eslint-disable-next-line
  }, [authenticated, canAddLoading]);

  if (!authenticated) {
    landingPageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  if (loading) {
    return <SkeletonHomeView canAdd={canAdd}/>;
  }

  return (
          <LazyHomeLoggedIn
            canAdd={canAdd}
            hasPass={hasPass}
            hasSub={hasSub}/>
  );
};

export default HomePage;
